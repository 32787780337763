<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">14th MONTH MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="released_no"
            dense
            label="Released #"
            :items="['1st Semi-Annual','2nd Semi-Annual']"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="selection"
            class="mx-2"
            dense
            outlined
            label="Selection"
            :items="['Active','Temporary Deactivate','Permanent Deactivate']"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="released_no==='1st Semi-Annual'?headers:headers3"
                    :items="payroll_data"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>14th Month Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.name
              }}
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.jan_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.jan_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.feb_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.feb_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.mar_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.mar_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.apr_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.apr_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.may_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.may_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='1st Semi-Annual'">
              <v-data-table v-if="item.jun_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.jun_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.jul_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.jul_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.aug_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.aug_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.sep_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.sep_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.oct_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.oct_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.nov_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.nov_data"
              >
              </v-data-table>
            </td>
            <td v-if="released_no==='2nd Semi-Annual'">
              <v-data-table v-if="item.dec_data.length>0"
                            dense
                            :headers="headers2"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :items="item.dec_data"
              >
              </v-data-table>
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ formatPrice(item.total_amount) }}
            </td>
            <td>
              {{ item.remarks}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      released_no: '',
      selection: '',
      status: '',
      year: '',
      year_items: [],
      payroll_data: [],
      duration: '',
      sorted_by: 'Name',
      bank_type: 'All',
      bank_type_items: [],
      position: 'All',
      position_items: [],
      batch_no: '',
      batch_no_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Jan.', value: 'employee.last_name', sortable: false},
        {text: 'Feb.', value: 'amount', sortable: false},
        {text: 'Mar.', value: 'bank_code_name', sortable: false},
        {text: 'Apr.', value: 'bank_code_name', sortable: false},
        {text: 'May', value: 'bank_code_name', sortable: false},
        {text: 'Jun.', value: 'bank_code_name', sortable: false},
        {text: 'Total', value: 'bank_code_name', sortable: false},
        {text: 'Grand Total', value: 'bank_code_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
      ],
      headers3: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Jul.', value: 'bank_code_name', sortable: false},
        {text: 'Aug.', value: 'bank_code_name', sortable: false},
        {text: 'Sep.', value: 'bank_code_name', sortable: false},
        {text: 'Oct.', value: 'bank_code_name', sortable: false},
        {text: 'Nov.', value: 'bank_code_name', sortable: false},
        {text: 'Dec.', value: 'bank_code_name', sortable: false},
        {text: 'Total', value: 'bank_code_name', sortable: false},
        {text: 'Grand Total', value: 'bank_code_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
      ],
      headers2: [
        {value: 'no_work', sortable: false},
        {value: 'no_absent', sortable: false},
        {value: 'highest_rate', sortable: false},
        {value: 'total', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('category', ['list_of_category_have_thirten_month_pay']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee', 'initialize_payroll_selected_category', 'initialize_payroll', 'payroll']),
      ...mapActions('fourteen_month_pay', ['initialize_fourteen_month_payroll']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year);
        data.append('remarks', this.selection);
        data.append('category_id', this.category_id);
        data.append('released_no', this.released_no);
        data.append('is_monitoring', 1);
        this.initialize_fourteen_month_payroll(data)
          .then(response => {
            this.payroll_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
